import React from 'react';

function ArrowLeftIcon() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='100%'
      height='100%'
      viewBox='0 0 50.739 40.314'
    >
      <g
        fill='none'
        stroke='currentColor'
        strokeWidth='1.5'
        data-name='Group 95471'
        opacity='0.752'
        transform='translate(.059 .033)'
      >
        <path
          d='M20.773 40.248S19.947 21.696 0 20.124'
          data-name='Path 75073'
        ></path>
        <path
          d='M20.773 0S19.947 18.552 0 20.124'
          data-name='Path 75074'
        ></path>
        <path
          d='M0 0L47.758 0'
          data-name='Line 1005'
          transform='translate(2.921 19.949)'
        ></path>
      </g>
    </svg>
  );
}

export default ArrowLeftIcon;
