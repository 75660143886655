import React, {useCallback, useEffect, useState} from 'react';
import { Link } from 'react-router-dom';
import GalleryCard from '../../../components/cards/GalleryCard';
import axios from "axios";

const GallerySection = () => {
  const [images, setImages] = useState([]);

  const getImages = useCallback(async () => {
    try {
      const res = await axios(`${process.env.REACT_APP_GALYPOINTS_API}/gallery`);
      setImages(res.data.slice(0,3))
    } catch (e) {

    }


    }, [process.env.REACT_APP_GALYPOINTS_API])
  useEffect(() => {
    getImages()
  }, [getImages])
  return (
    <section id='Gallery' className='container gallery-section mb-5 pb-5'>
      <div className='big-title font-migra text-uppercase mb-5 pb-3'>
        GALLERY
      </div>
      <div className='position-relative'>
        <div className='row row-cols-2 row-cols-lg-3'>
          {images.map((item, index) => {
            return (
              <div className='p-0' key={index}>
                <GalleryCard item={item} />
              </div>
            );
          })}
        </div>
        <span
          className='position-absolute end-0'
          style={{ bottom: '-9.5rem', zIndex: '-1' }}
        >
          <img
            className='d-block h-100 w-100'
            src='/assets/images/sketch arrow.svg'
            alt='...'
          />
        </span>
      </div>

      <div className='mt-5 text-center'>
        <Link
          to='/gallery'
          className='btn btn-g-primary rounded-pill px-5 py-2 text-capitalize'
        >
          View All
        </Link>
      </div>
    </section>
  );
};

export default GallerySection;
