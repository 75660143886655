import {createSlice} from '@reduxjs/toolkit';


const galypointsSlice = createSlice({
    name: 'galypoints',
    initialState: { wallet: null, totalPoints: 0},
    reducers: {
        setWallet(state, action) {
           state.wallet = action.payload;
        },
        setTotalPoints(state, action) {
           state.totalPoints = action.payload;
        }
    }
});

export const galypointsActions = galypointsSlice.actions;

export default galypointsSlice;
