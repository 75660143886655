import {createSlice} from '@reduxjs/toolkit';


const responseModalSlice = createSlice({
    name: 'responseModal',
    initialState: { isOpen: false, type: null},
    reducers: {
        toggleModal(state, action) {
            state.isOpen = action.payload.isOpen;
            state.type = action.payload.type;
        },


    }
});

export const responseModalActions = responseModalSlice.actions;

export default responseModalSlice;
