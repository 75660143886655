export interface StoreItem {
    stock: number;
    totalLeft: number;
    price: number;
    name: string;
    description: string;
    type: StoreItemType
    needDetails: 'YES' | 'NO';
    file: string;
    id: string
    status: string;


    // stock: 555
    // totalLeft: 555
    // type: "Raffle"
}

export enum StoreItemType  {
    RAFFLE = 'Raffle',
    REGULAR = 'Regular',
    WHITELIST = 'Whitelist'
}
