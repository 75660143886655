
import BannerCard from '../../../components/banners/BannerCard'
import { Swiper, SwiperSlide, SwiperProps } from "swiper/react";

import React, {useCallback, useEffect, useState} from "react";
import {Navigation, Pagination} from "swiper";
import axios from "axios";

type Props = {}

const BannerSection = (props: Props) => {
    const [banners, setBanners] = useState([]);

    const getBanners = useCallback(async () => {
        try {
            const res = await axios(`${process.env.REACT_APP_GALYPOINTS_API}/banner`);
            setBanners(res.data);
        } catch (e) {

        }


    }, [process.env.REACT_APP_GALYPOINTS_API])
    useEffect(() => {
        getBanners()
    }, [getBanners])
  return (
    <section className='container banner-section mb-5 pb-5'>
      <Swiper
          className="bannerSwiper"
          spaceBetween={30}
          slidesPerView={1}
          modules={[Pagination, Navigation]}
          pagination={{ clickable: true }}
          navigation
      >
        {banners.map((item, index) => (
            <SwiperSlide key={index}>
              <BannerCard banner={item} className="banner-2" />
            </SwiperSlide>
        ))}
      </Swiper>

    </section>
  )
}

export default BannerSection
