import React from 'react';

type Props = {};

const AboutTheArtist = (props: Props) => {
  return (
    <div
      className='bg-fixed'
      style={{ backgroundImage: 'url(/assets/bg-star.gif)' }}
    >
      <div
        className='bg-fixed bg-fixed-bottom-right'
        style={{ backgroundImage: 'url(/assets/images/gal.png)' }}
      >
        <section className='container'>
          <div className='row'>
            <div className='col-12 col-md-9 col-lg-7'>
              <div className='py-5'>
                <div className='display-1 lh-small font-migra text-uppercase mb-4'>
                  GAL YOSEF
                </div>
                <p className='mb-4 lead lh-lg text-white text-opacity-75'>
                  Gal Yosef has become a world-renowned artist in a short span
                  of time. A self-taught prodigy in the fields of 3D art and
                  digital sculpting. Gal Yosef first specialized in reimagined
                  designs of well-loved cartoon characters and avatars forged
                  from his own imagination before focusing on creating his own
                  characters.
                </p>
                <p className='mb-4 lead lh-lg text-white text-opacity-75'>
                  A curiosity and passion for painting and drawing inspired Gal
                  to begin experimenting with 3D design when he was just 12
                  years old. Gal Yosef’s art continues to evolve in style; his
                  latest pieces depict a darker, avant-garde version of his
                  imagined cartooniverse. Gal has evolved his career from a
                  digital studio owner who specialized in 3D art to a recognized
                  and major gallery-sponsored artist. He garnered even more
                  recognition as one of the most talked about artists at Miami’s
                  Art Basel 2021.
                </p>
                <div className='mt-5'>
                  <ul className='nav gap-2 h-100'>
                    <li>
                      <a
                        href='http://www.discord.com/invite/galyverse'
                        target='_blank'
                        rel='noopener noreferrer'
                        className='btn p-0 icon-size-2'
                      >
                        <img
                          className='d-block h-100 w-100'
                          src='/assets/images/discord footer.svg'
                          alt='...'
                        />
                      </a>
                    </li>
                    <li>
                      <a
                        href='http://www.instagram.com/galyversenft'
                        target='_blank'
                        rel='noopener noreferrer'
                        className='btn p-0 icon-size-2'
                      >
                        <img
                          className='d-block h-100 w-100'
                          src='/assets/images/insta footer.svg'
                          alt='...'
                        />
                      </a>
                    </li>
                    <li>
                      <a
                          href="https://twitter.com/galyverse"
                        target='_blank'
                        rel='noopener noreferrer'
                        className='btn p-0 icon-size-2'
                      >
                        <img
                          className='d-block h-100 w-100'
                          src='/assets/images/twitter footer.svg'
                          alt='...'
                        />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default AboutTheArtist;
