import {useWeb3React} from "@web3-react/core";
import {useCallback, useEffect, useState} from "react";

export const useConnected = () => {
    const [isConnected, setIsConnected] = useState(false);
    const checkLocalStorage = useCallback(() => {
        const auth = localStorage.getItem('auth');
        setIsConnected(!!auth);
    }, []);

    useEffect(() => {
        checkLocalStorage();
        window.addEventListener('storage', checkLocalStorage);
        return () => window.removeEventListener('storage', checkLocalStorage);
    }, [checkLocalStorage]);

    return [isConnected] as const;
}
