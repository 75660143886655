import classes from "./eyrie.module.scss";
import React, { useEffect, useState } from "react";
import axios from "axios";
import { EagleCard } from "./eagle-card/eagle-card";
import { useApi } from "../../shared/hooks/useApi";
import { DefaultRootState, useSelector } from "react-redux";
import useResponsiveQuery from "../../utils/isMobile";
import { useConnected } from "../../shared/hooks/useConnected";
import { GalyModal } from "../Marketplace/components/modal/galy-modal";
import { dummyEagles, dummyPurchases } from "./dummyData";

export const Eyrie = () => {
  const [eagles, setEagles] = useState<any>([]);
  const { totalPoints } = useSelector(
    (state: DefaultRootState) => state.galypoints
  );
  const isMobile = useResponsiveQuery();
  const [isConnected] = useConnected();
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  useEffect(() => {
    // getEagles()
  }, []);

  const [requestPurchases, purchasesData, isLoading, error] = useApi<any>(
    `purchase`,
    {
      defaultData: [],
    }
  );

  const [requestEyrie, eyrieData, eyrieIsLoading, eyrieError] = useApi<any>(
    `eyrie`,
    {
      defaultData: [],
    }
  );

  useEffect(() => {
    requestPurchases();
    requestEyrie();
  }, [isConnected]);
  // https://mec-4k.s3.eu-central-1.amazonaws.com/Eagles+-+4k/Eagles+-+4k/100.png

  if (isConnected)  return (
    <div
      className="bg-fixed"
      style={{ backgroundImage: "url(/assets/bg-star.gif)" }}
    >
      <div className={`${classes.wrapper} container`}>
        {isMobile && (
          <div className={classes.mobilePoints}>
            <div className={`font-migra text-uppercase fs-2`}>MY EYRIE</div>
            <div className={`${classes.points} d-flex align-items-center`}>
              <span className="me-2">
                Your credit: {totalPoints.toLocaleString()}
              </span>
              <button type="button" className="btn p-0 d-block icon-size-small">
                <img
                  className="d-block h-100 w-100"
                  src="/assets/images/info.svg"
                  alt="..."
                />
              </button>
              <button type="button" className="btn p-0 ms-3 icon-size-2">
                <img
                  className="d-block h-100 w-100"
                  src="/assets/images/apps-grid.svg"
                  alt=""
                />
              </button>
            </div>
          </div>
        )}
        <div className={classes.title}>
          <span
            className={`d-none d-lg-block big-title font-migra text-uppercase`}
          >
            {" "}
            MY EYRIE{" "}
          </span>
          {!isMobile && (
            <span className={`${classes.credit} d-flex align-items-center`}>
              <span className="me-2">
                Your Credit: {totalPoints.toLocaleString()}
              </span>
              <button type="button" className="btn p-0 d-block icon-size-small">
                <img
                  className="d-block h-100 w-100"
                  src="/assets/images/info.svg"
                  alt="..."
                />
              </button>
              <button type="button" className="btn p-0 ms-3 icon-size-2">
                <img
                  className="d-block h-100 w-100"
                  src="/assets/images/apps-grid.svg"
                  alt=""
                />
              </button>
            </span>
          )}
        </div>
        <section className="mt-5">
          <div className={`row row-cols-1 row-cols-md-2 row-cols-xl-3`}>
            {eyrieData.map((item: any, index: number) => <EagleCard isEagle={true} key={index} eagle={item} />)}

          </div>
          <div className={classes.title}>
            <span className={classes.text}> Redeems </span>
            {!isMobile && (
              <span className={classes.credit}>
                Here you can find all your redeems history
              </span>
            )}
          </div>
          <div
            className={`${classes.eagles} ${isMobile && classes.mobileWrapper}`}
          >
            {purchasesData.map((item: any, index: number) => (
              <EagleCard isEagle={false} key={index} eagle={item} />
            ))}
          </div>
        </section>
      </div>
    </div>
  );

  return (
      <div className={classes.notConnectedWrapper}>
          <button onClick={handleOpen}
                  className={`${classes.buyButton} ${isMobile && classes.buyButtonMobile}
                       `}>
             Connect Wallet
          </button>
          <GalyModal isOpen={open} onClose={handleClose}/>
      </div>
  )
};
