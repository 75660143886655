import React from 'react';

type Props = {
    isMarketplace: boolean;
};

const ComingSoonBadge = (props: Props) => {
    const {isMarketplace} = props;
  return (
    <span className='position-absolute bottom-0 end-0'>
      <span className={`badge-1 ${isMarketplace && 'market-tag' }`}>
        <img
          className='d-block h-100 w-100'
          src='/assets/images/Coming-soon.svg'
          alt='...'
        />
        <span className={`badge-1-name text-white fw-bold`} >Coming soon</span>
      </span>
    </span>
  );
};

export default ComingSoonBadge;
