
import {configureStore} from "@reduxjs/toolkit";
import galypointsSlice from "./slices/galypoints-slice";
import marketplaceSlice from "./slices/marketplace-slice";
import responseModalSlice from "./slices/response-modal-slice";



const store = configureStore({
    reducer: {
        galypoints: galypointsSlice.reducer,
        marketplace: marketplaceSlice.reducer,
        responseModal: responseModalSlice.reducer
    }
});

declare global {
    type RootState = ReturnType<typeof store.getState>
}

declare module 'react-redux' {
    interface DefaultRootState extends RootState {}
}

export default store;
