import { Route, Routes } from 'react-router-dom';
import AboutTheArtist from './pages/About/AboutTheArtist';
import AboutTheProject from './pages/About/AboutTheProject';
import GalleryPage from './pages/gallery/GalleryPage';
import HomePage from './pages/home/HomePage';
import {Marketplace} from "./pages/Marketplace/Marketplace";
import {Eyrie} from "./pages/eyrie/eyrie";
import {GalyPoints} from "./pages/galypoints/galypoints";

const Paths = () => {
  const pathsList = [
    { path: '/', component: <HomePage /> },
    { path: '/gallery', component: <GalleryPage /> },
    { path: '/about-project', component: <AboutTheProject /> },
    { path: '/about-artist', component: <AboutTheArtist /> },
    { path: '/marketplace', component: <Marketplace />},
    { path: '/eyrie', component: <Eyrie />},
    { path: '/galypoints', component: <GalyPoints />}

  ];

  return (
    <Routes>
      {pathsList.map((item, index) => (
        <Route path={`${item.path}`} element={item.component } key={index} />
      ))}
    </Routes>
  );
};

export default Paths;
