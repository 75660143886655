import React from 'react';
import {Link, NavLink} from 'react-router-dom';
import { HashLink } from "react-router-hash-link";

const FooterPrimary = () => {
  const links = [
    { name: "Home", link: "/#Home" },
    { name: "About The Project", link: "/about-project", isPage: true },
    { name: "RoadMap", link: "/#RoadMap" },
    { name: "Galypoints", link: "/#Galypoints" },
    { name: "Marketplace", link: "/marketplace", isPage: true },
    { name: "Gallery", link: "/#Gallery" },
    { name: "About The Artist", link: "/about-artist", isPage: true },
    { name: "Team", link: "/#Team" },
    {name: 'Terms Of Service', link: `${process.env.PUBLIC_URL}/terms.pdf`, isDocument: true},
    {name: 'Privacy Notice', link: `${process.env.PUBLIC_URL}/privacy.pdf`, isDocument: true}
    // { name: "F&Q", link: "/#Faq" },
  ];

  return (
    <footer className='border-top border-dark pt-3 pb-5'>
      <div className='container'>
        <div className='row'>
          <div className='col-12 col-lg-6'>
            <div>
              <ul className='nav row-cols-2 row-cols-lg-3 gy-3 cl-effect-5'>
                {links.map((item, index) => (
                  <li key={index}>
                    {item.isDocument ? <a target='_blank' href={item.link}>  <span data-hover={item.name}>{item.name}</span> </a> : item.isPage ? (
                        <NavLink to={item.link} className="text-capitalize">
                          <span data-hover={item.name}>{item.name}</span>
                        </NavLink>
                    ) : (
                        <HashLink smooth to={item.link}>
                          <span data-hover={item.name}>{item.name}</span>
                        </HashLink>
                    )}
                  </li>
                ))}
              </ul>
            </div>
          </div>
          <div className='col'>
            <div className='nav-links h-100'>
              <ul className='nav justify-content-end align-items-end gap-2 h-100'>
                <li>
                  <a
                    href='http://www.discord.com/invite/galyverse'
                    target='_blank'
                    rel='noopener noreferrer'
                    className='btn p-0 icon-size-2'
                  >
                    <img
                      className='d-block h-100 w-100'
                      src='/assets/images/discord footer.svg'
                      alt='...'
                    />
                  </a>
                </li>
                <li>
                  <a
                    href='http://www.instagram.com/galyversenft'
                    target='_blank'
                    rel='noopener noreferrer'
                    className='btn p-0 icon-size-2'
                  >
                    <img
                      className='d-block h-100 w-100'
                      src='/assets/images/insta footer.svg'
                      alt='...'
                    />
                  </a>
                </li>
                <li>
                  <a
                      href="https://twitter.com/galyverse"
                    target='_blank'
                    rel='noopener noreferrer'
                    className='btn p-0 icon-size-2'
                  >
                    <img
                      className='d-block h-100 w-100'
                      src='/assets/images/twitter footer.svg'
                      alt='...'
                    />
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default FooterPrimary;
