import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";

type Props = {};

const AboutTheProject = (props: Props) => {
  const location = useLocation();

  useEffect(() => {

    window.scrollTo(0, 0);
  }, [location]);
  return (
    <div
      className="bg-fixed"
      style={{ backgroundImage: "url(/assets/bg-star.gif)" }}
    >
      <div
        className="bg-fixed bg-fixed-right"
        style={{ backgroundImage: "url(/assets/images/eden.png)" }}
      >
        <section className="container">
          <div className="row">
            <div className="col-12 col-md-9 col-lg-7">
              <div className="py-5">
                <div className="display-1 lh-small font-migra text-uppercase mb-5">
                  ABOUT META EAGLE CLUB
                </div>
                <p className="mb-4 lead lh-lg text-white text-opacity-75">
                  Meta Eagle Club by Galyverse is an NFT collection of 12,000 digital art collectibles in the form of 3D, real life-inspired eagles.
                  This NFT art project has been created by the increasingly successful artist Gal Yosef and  curated  by Eden Gallery.

                </p>
                <p className="mb-4 lead lh-lg text-white text-opacity-75">
                  Owning an Eagle grants you access to unique experiences, NFT airdrops, significant collaborations, and many more exclusive benefits, including real life luxury events powered by Eden Gallery

                </p>
              </div>
              <div className="pb-5">
                <div className="display-3 lh-small font-migra text-uppercase mb-4">
                  EDEN GALLERY
                </div>
                <p className="mb-4 lead lh-lg text-white text-opacity-75">
                  Since its founding in 1997, Eden Gallery has evolved into one
                  of the largest global networks of high-end art galleries.
                  Collectively, Eden artists represent and promote contemporary
                  optimism and a colorful view of life.
                </p>
                <p className="mb-4 lead lh-lg text-white text-opacity-75">
                  Eden offers premiere gallery spaces, including locations in
                  New York, London, Miami, Mykonos, Aspen, and Dubai.
                </p>
                <div className="pt-3">
                  <ul className="nav gap-2">
                    <li>
                      <span className="icon-size-3">
                        <img
                          className="d-block h-100 w-100"
                          src="/assets/images/eden logo.svg"
                          alt=".."
                        />
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default AboutTheProject;
