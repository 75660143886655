import {createSlice} from '@reduxjs/toolkit';


const marketplaceSlice = createSlice({
    name: 'marketplace',
    initialState: { items: [], isLoading: true},
    reducers: {
        setItems(state, action) {
            state.items = action.payload;
        },
        setIsLoading(state,action) {
            state.isLoading = action.payload
        }

    }
});

export const marketplaceActions = marketplaceSlice.actions;

export default marketplaceSlice;
