import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";

const TeamSection = () => {
  const team = [
    { src: "/assets/images/eden logo.svg" },
    { src: "/assets/images/galogo.svg" },
  ];

  return (
    <section id="Team" style={{marginTop: '60px'}} className="container roadMap-section mb-5 pb-5">
      <div className="big-title font-migra text-uppercase mb-5 text-center">
        TEAM
      </div>
      <div className="swiper-center">
        <Swiper
          slidesPerView={3}
          spaceBetween={15}
          breakpoints={{
            640: {
              slidesPerView: 4,
              spaceBetween: 20,
            },
            768: {
              slidesPerView: 6,
              spaceBetween: 25,
            },
            1024: {
              slidesPerView: 10,
              spaceBetween: 30,
            },
          }}
        >
          {team.map((item, i) => (
            <SwiperSlide key={i}>
              <div className="team-item">
                <img
                  className="d-block h-100 w-100"
                  src={`${item.src}`}
                  alt="..."
                />
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </section>
  );
};

export default TeamSection;
