import React from "react";
import { Link, NavLink } from "react-router-dom";

const HeroSection = () => {
  return (
    <section className="container hero-section">
      <div className="row gy-4">
        <div className="col-12 col-lg-6 col-xl-5">
          <div className="py-5">
            <ul className="nav flex-column gap-4">
              <li>
                <div className="big-title-1 font-migra">
                  META <br /> EAGLE <br /> CLUB
                </div>
              </li>
              <li>
                <p className="mb-0 fw-normal lh-lg">

                  Meta Eagle Club, created by 3D artist Gal Yosef, is the collection that started the Galyverse.
                  Meta Eagle Club members get to enjoy exclusive perks and benefits in the world of luxury & fine art. These perks include exclusive events in Eden Galleries around the world, Physical art raffles, Flight experiences, Metaverse and of course the Galypoints system.

                  Join Meta Eagle Club today by purchasing an eagle on Opensea!
                </p>
              </li>
              <li>
                <ul className="nav gap-2">
                  <li>
                    <NavLink
                      to="/marketplace"
                      className="btn btn-secondary rounded-pill px-4 py-2 text-capitalize"
                    >
                      Market Place
                    </NavLink>
                  </li>
                  <li>
                    <a
                      href="https://opensea.io/collection/metaeagleclub-mec"
                      target="_blank"
                      className="btn btn-g-primary rounded-pill px-4 py-2 text-capitalize d-flex align-items-center"
                    >
                      <span className="icon-size-small me-2">
                        <img
                          className="d-block h-100 w-100"
                          src="/assets/images/opensea label Desktop button.svg"
                          alt="..."
                        />
                      </span>
                      <span>Buy an Eagle</span>
                    </a>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
        <div className="col order-first order-lg-last">
          <div className="opacity-75">
            <img
              className="d-block h-100 w-100"
              src="/assets/images/Hero-img.png"
              alt="..."
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default HeroSection;
