import classes from "./item-popup.module.scss";
import { StoreItem, StoreItemType } from "../../../../interfaces/StoreItem";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { GalyModal } from "../modal/galy-modal";

import useResponsiveQuery from "../../../../utils/isMobile";
import { Checkbox, TextField } from "@mui/material";
import { useConnected } from "../../../../shared/hooks/useConnected";
import { useApi } from "../../../../shared/hooks/useApi";
import { useDispatch } from "react-redux";
import { responseModalActions } from "../../../../store/slices/response-modal-slice";
import { usePoints } from "../../../../shared/hooks/usePoints";
import { getMarketplaceItems } from "../../../../store/actions/marketplace-actions";
import { marketplaceActions } from "../../../../store/slices/marketplace-slice";
import axios from "axios";

type ComponentProps = {
  item: StoreItem;
  closePopup: () => void;
  totalPoints: number;
};

enum ClickTypes {
  PLUS = 0,
  MINUS = 1,
}

export const ItemPopup = (props: ComponentProps) => {
  const isMobile = useResponsiveQuery();

  const { item, closePopup, totalPoints } = props;
  const emailRef = useRef<any>();

  const [count, setCount] = useState<number>(1);
  const [price, setPrice] = useState<number>(0);
  const [isConnected] = useConnected();
  const dispatch = useDispatch();
  const [isFlipped, setIsFlipped] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<any>(null);

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [checked, setChecked] = useState(false);
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
  };

  const [request, response, isLoading, error] = useApi<any>(
    `purchase/buy/${item.id}`,
    {
      defaultData: undefined,
    }
  );
  const [getPoints] = usePoints();

  const getMarketplaceItems = useCallback(async () => {
    dispatch(marketplaceActions.setIsLoading(true));

    try {
      const response = await axios.get(
        `${process.env.REACT_APP_GALYPOINTS_API}/product`
      );
      dispatch(marketplaceActions.setItems(response.data));
    } catch (e) {
    } finally {
      dispatch(marketplaceActions.setIsLoading(false));
    }
  }, []);
  useEffect(() => {
    dispatch(
      responseModalActions.toggleModal({ isOpen: true, type: "success" })
    );
  }, []);

  useEffect(() => {
    if (error) {
      dispatch(
        responseModalActions.toggleModal({ isOpen: true, type: "error" })
      );
      closePopup();
    }
    if (response) {
      dispatch(
        responseModalActions.toggleModal({ isOpen: true, type: "success" })
      );
      getPoints();
      getMarketplaceItems();
      closePopup();
    }
  }, [response, error, getMarketplaceItems]);

  const handleCountClick = (clickType: ClickTypes) => {
    return;
    if (count === item.totalLeft && clickType === ClickTypes.PLUS) {
      return;
    }
    if (clickType === 1) {
      if (count === 1) {
        return;
      }
      setCount((prev) => prev - 1);
      setPrice((prev: number) => prev - item.price);
      return;
    }
    if (count === 10) {
      return;
    }
    setCount((prev) => prev + 1);
    setPrice((prev: number) => prev + item.price);
  };

  useEffect(() => {
    setCount(1);
    setPrice(item.price);
    setIsFlipped(false);
    setErrorMessage(null);
  }, [item]);

  const buyButtonClickHandler = () => {
    if (
      item.status === "Soon" ||
      item.totalLeft === 0 ||
      (price > totalPoints && isConnected)
    )
      return;
    if (!isConnected) {
      handleOpen();
    } else {
      setIsFlipped((prev) => !prev);
    }
  };

  const validateEmail = (email: string) => {
    const regex = new RegExp("[a-z0-9]+@[a-z]+.[a-z]{2,3}");

    return regex.test(email);
  };

  function onSubmit() {
    if (!checked) {
      setErrorMessage("Please make sure you accept the terms and conditions");
      return;
    }
    if (item.needDetails === "YES" && emailRef?.current?.value === "") {
      setErrorMessage("E-mail is required");
      return;
    }
    if (
      item.needDetails === "YES" &&
      !validateEmail(emailRef?.current?.value)
    ) {
      setErrorMessage("Please enter valid e-mail");
      return;
    } else {
      setErrorMessage(null);
    }

    request({
      method: "POST",
      body: JSON.stringify({
        email: emailRef?.current?.value,
        purchaseAmount: count,
      }),
    });
  }

  return (
    <>
      {/*<ReactCardFlip containerClassName={classes.test} isFlipped={isFlipped} flipDirection="horizontal">*/}
      <div className={`${classes.test} ${classes.flipCard}`}>
        <div
          className={`${classes.flipCardInner}  ${
            isFlipped && classes.IsFlipped
          }`}
        >
          <div
            className={`${classes.wrapper} ${
              isMobile && classes.mobileWrapper
            } ${classes.flipCardFront}`}
          >
            <div className={classes.actionButtons}>
              <span className={classes.exit} onClick={closePopup}>
                X
              </span>
            </div>

            <div className={classes.imageWrapper}>
              <img
                className={`${classes.image} ${
                  isMobile && classes.mobileImage
                } `}
                alt={item?.file}
                src={item?.file}
              />
            </div>
            <div className={classes.titleAndSubTitle}>
              <p>{item?.name}</p>
              <p className={classes.subtitle}>{item?.description}</p>
            </div>
            {item.totalLeft > 0 && (
              <div className={classes.quantity}>
                {/*<span>Quantity</span>*/}
                <div className={classes.quantityDetails}>
                  {/*<div className={classes.quantityController}>*/}
                  {/*<button className={classes.button}*/}
                  {/*        onClick={() => handleCountClick(ClickTypes.MINUS)}>-*/}
                  {/*</button>*/}
                  {/*<span>{count}</span>*/}
                  {/*<button className={classes.button} onClick={() => handleCountClick(ClickTypes.PLUS)}>+*/}
                  {/*</button>*/}
                  {/*</div>*/}
                  <div className={classes.price}>
                    {price.toLocaleString()} Points {isConnected}
                  </div>
                </div>
              </div>
            )}
            <button
              onClick={buyButtonClickHandler}
              className={`${classes.buyButton} ${
                (item.totalLeft === 0 || item.status === "Soon") &&
                classes.disabled
              } ${isMobile && classes.buyButtonMobile} 
                            ${
                              price > totalPoints &&
                              isConnected &&
                              classes.disabled
                            }`}
            >
              {item.status === "Soon"
                ? "Coming Soon"
                : item.totalLeft === 0
                ? "Sold Out!"
                : isConnected
                ? price > totalPoints
                  ? "Insufficient funds"
                  : "Buy Now"
                : "Connect Wallet"}
            </button>
          </div>
          <div
            className={`${classes.wrapper} ${
              isMobile && classes.mobileWrapper
            }  ${classes.flipCardBack}`}
          >
            <div
              className={`${classes.actionButtons} ${
                isMobile && classes.mobileButtons
              }`}
            >
              <span className={classes.exit} onClick={closePopup}>
                X
              </span>
              <span
                onClick={() => setIsFlipped(false)}
                className={classes.exit}
              >
                Go back
              </span>
            </div>
            <div className={classes.imageWrapper}>
              <img
                className={`${classes.image} ${
                  isMobile && classes.mobileImage
                }`}
                alt={item?.file}
                src={item?.file}
              />
            </div>
            <div className={classes.titleAndSubTitle}>
              {item.type === StoreItemType.RAFFLE && (
                <span>
                  This is a raffle! after our confirmation you will get a ticket
                  with your raffle number.
                </span>
              )}
              {item.type === StoreItemType.REGULAR && (
                <span>
                  This a regular item! after confirmation you will receive the
                  item
                </span>
              )}
              {item.type === StoreItemType.WHITELIST && (
                <span>
                  This is a whitelist! You will get the whitelist to this
                  wallet. be aware! one purchase per wallet!
                </span>
              )}
              {item.needDetails === "YES" ? (
                <div className={classes.userDetails}>
                  <div>
                    <Checkbox
                      checked={checked}
                      onChange={handleChange}
                      inputProps={{ "aria-label": "controlled" }}
                    />
                    <span>
                      I have read and confirm the{" "}
                      <a
                        target="_blank"
                        href="/terms"
                        className={classes.terms}
                      >
                        Terms and Conditions
                      </a>
                    </span>

                    <TextField
                      sx={{ width: "100%", color: "white" }}
                      inputRef={emailRef}
                      label={"email"}
                    />
                    <span> {errorMessage}</span>
                    {isLoading ? (
                      <button
                        className={`${classes.disabled} ${classes.buyButton}`}
                      >
                        Processing...
                      </button>
                    ) : (
                      <button
                        disabled={isLoading}
                        onClick={onSubmit}
                        className={`${classes.buyButton}`}
                      >
                        APPLY
                      </button>
                    )}
                  </div>
                </div>
              ) : isLoading ? (
                <button className={`${classes.disabled} ${classes.buyButton}`}>
                  Processing...
                </button>
              ) : (
                <button
                  onClick={onSubmit}
                  disabled={isLoading}
                  className={`${classes.buyButton}`}
                >
                  APPLY
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
      {/*</ReactCardFlip>*/}
      <GalyModal isOpen={open} onClose={handleClose} />
    </>
  );
};
