import React, { useState, useEffect } from "react";

import { StoreItem } from "../../interfaces/StoreItem";
import { StoreItemComponent } from "./components/store-item/store-item-component";
import classes from "./marketplace.module.scss";
import { ItemPopup } from "./components/item-popup/item-popup";
// import useResponsiveQuery from "../../utils/isMobile";
import { BuyAnEagleFooter } from "./components/buy-an-eagle-footer/buy-an-eagle-footer";
import { DefaultRootState, useSelector } from "react-redux";
import { Bars } from "react-loader-spinner";
import useResponsiveQuery from "../../utils/isMobile";
import { useLocation } from "react-router-dom";

export const Marketplace = () => {
  const location = useLocation();

  const isMobile = useResponsiveQuery();

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [itemToShow, setItemToShow] = useState<StoreItem>();

  const { wallet, totalPoints } = useSelector(
    (state: DefaultRootState) => state.galypoints
  );
  const { items, isLoading } = useSelector(
    (state: DefaultRootState) => state.marketplace
  );

  const handleCallback = (item: StoreItem) => {
    setIsModalOpen(true);
    setItemToShow(item);
  };
  const modalHandler = () => {
    if (isModalOpen) {
      setIsModalOpen(false);
    }
  };

  useEffect(() => {

    window.scrollTo(0, 0);
  }, [location]);

  return (
    <div className={`${classes.Container} container`}>
      {isLoading ? (
        <div className={classes.loading}>
          <Bars color="#00BFFF" height={150} width={150} />
        </div>
      ) : (
        <div
          className={`${classes.wrapper} ${isMobile && classes.mobileWrapper}`}
          onClick={modalHandler}
        >
          <div className={classes.title}>MARKETPLACE</div>
          <a target='_blank' style={{textAlign: 'center', textDecoration: 'underline', marginBottom: '24px'}} href={`${process.env.PUBLIC_URL}/store-rules.pdf`}>MEC Store rules</a>
          <div
            className={`${classes.itemsWrapper} ${
              isMobile && classes.mobileItemsWrapper
            }`}
          >
            {items?.map(
              (item: StoreItem) =>
                item.status !== "Hold" && (
                  <StoreItemComponent
                    callback={handleCallback}
                    key={item.id}
                    item={item}
                  />
                )
            )}
          </div>
          {/*{!isMobile &&  <BuyAnEagleFooter/> }*/}
        </div>
      )}
      <div
        className={`${classes.popup} ${isMobile && classes.mobilePopup} ${
          isModalOpen && classes.active
        }`}
      >
        {itemToShow && (
          <ItemPopup
            totalPoints={totalPoints}
            item={itemToShow}
            closePopup={() => setIsModalOpen(false)}
          />
        )}
      </div>
    </div>
  );
};
