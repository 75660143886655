import {StoreItem} from "../../../../interfaces/StoreItem";
import classes from './store-item-component.module.scss';
import useResponsiveQuery from "../../../../utils/isMobile";
import SoldOutBadge from "../badges/SoldOutBadge";
import ComingSoonBadge from "../badges/ComingSoonBadge";
import RegularBadge from "../badges/RegularBadge";

type ComponentProps = {
    item: StoreItem
    callback: (item: StoreItem) => void
}

export const StoreItemComponent = (props: ComponentProps) => {

    const {item, callback} = props;
    const isMobile = useResponsiveQuery();
    const handleClick = () => {
        callback(item)
    }

    return (
        <div className={`${classes.wrapper} ${isMobile && classes.mobileWrapper}`}>

            <div onClick={handleClick} className={classes.image} style={{backgroundImage: `url(${item.file})`}}>
                {/*<div className={classes.price}>*/}
                    {/*{item.price.toLocaleString()} Points*/}
                {item.status === 'Soon' && <ComingSoonBadge isMarketplace={true} /> }
                {item.status === 'Running' && item.totalLeft > 0 && <RegularBadge  isMarketplace={true} price={item.price} /> }
                {item.totalLeft === 0 && <SoldOutBadge isMarketplace={true} /> }


                {/*</div>*/}
            </div>
           <div className={classes.itemDetails}>
              <div className={classes.title}> {item.name}</div>
              <div className={classes.remaining}> {item.totalLeft} / {item.stock} Remaining</div>
           </div>

        </div>
    )
}
