import React from 'react';

type Props = {};

const SoldOutBadge = (props: Props) => {
  return (
    <span className='position-absolute bottom-0 end-0'>
      <span className='badge-1'>
        <img
          className='d-block h-100 w-100'
          src='/assets/images/Sold-out.svg'
          alt='...'
        />
        <span className='badge-1-name text-white fw-bold'>Sold Out</span>
      </span>
    </span>
  );
};

export default SoldOutBadge;
