import React, {useState} from "react";
import {Modal} from "react-bootstrap";

const GalleryCard = ({item}: { item: any }) => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <>
      <button
        type="button"
        className="btn p-0 h-100 w-100 position-relative"
        onClick={handleShow}
      >
        {item.type !== 'video' ? <img
            style={{objectFit: 'cover'}}
            className="d-block h-100 w-100"
            src={item.image}
            alt=".."
          /> :
          <video style={{width: '100%', height: '100%', objectFit: 'cover'}}>
            <source
              src={`${item.image}#t=0.1`}
              type="video/mp4"/>
          </video>
        }
        {item.type === "video" && (
          <span className="pos-center icon-size-3">
            <img
              className="d-block h-100 w-100"
              src="/assets/images/video-play-button.png"
              alt="..."
            />
          </span>
        )}
      </button>

      <Modal show={show} onHide={handleClose} centered size="lg">
        <div>
          {item.type === "video" ? (
            <video
              className="d-block w-100 border-0"
              src={`${item.image}`}
              title="YouTube video player"
              controls
              autoPlay
            ></video>
          ) : (
            <img className="d-block h-100 w-100" src={item?.image} alt=".."/>
          )}
        </div>
      </Modal>
    </>
  );
};

export default GalleryCard;
