import React, {useState} from "react";
import {Link} from "react-router-dom";

type Props = {
    className: string;
    banner: any;
};

export interface ISignUpData {
    image: string;
    title: string;
    subtitle: string;
    action: string;
    actionText: string;
}

const BannerCard = (props: Props) => {

    const {banner} = props;

    return (
        <>
            <div
                className={`bg-cover-center py-4 px-5 d-flex flex-column justify-content-end ${props.className} `}
                style={{backgroundImage: ` linear-gradient(to bottom, rgba(255,255,255, 0) 0%, rgba(0,0,0, 1) 100%),url(${banner.image})`}}
            >

                <div className="px-0 col-12 col-md-7 col-lg-5 col-xl-4 text-white">
                    <h1 className="mb-2 fw-bolder">{banner.title}</h1>
                    <p className="mb-4 text-white text-opacity-75 text-truncate-line-3">
                        {banner.subtitle}
                    </p>
                    {banner.actionText && <Link
                        to={banner.action}
                        className="btn btn-outline-light rounded-pill px-4"
                    >
                        {banner.actionText}
                    </Link>}
                </div>
            </div>
        </>
    );
};

export default BannerCard;
