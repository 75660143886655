import {useCallback, useEffect, useState} from "react";
import BannerSection from "./Sections/BannerSection";
import CollectionSection from "./Sections/CollectionSection";
import FaqSection from "./Sections/FaqSection";
import GallerySection from "./Sections/GallerySection";
import GalyPointsSection from "./Sections/GalyPointsSection";
import HeroSection from "./Sections/HeroSection";
import MarketPlaceSection from "./Sections/MarketPlaceSection";
import RoadMapSection from "./Sections/RoadMapSection";
import TeamSection from "./Sections/TeamSection";
import VideoSection from "./Sections/VideoSection";
import axios from "axios";

const HomePage = () => {
  const [videos, setVideos] = useState([]);


  const getImages = useCallback(async () => {
    try {
      const res = await axios(`${process.env.REACT_APP_GALYPOINTS_API}/video`);
      setVideos(res.data);

    } catch (e) {

    }


  }, [process.env.REACT_APP_GALYPOINTS_API])
  useEffect(() => {
    getImages()
  }, [getImages])
  return (
    <div
      id="Home"
      className="bg-fixed home-page"
      style={{ backgroundImage: "url(/assets/bg-star.gif)" }}
    >
      <HeroSection />
      {videos.length > 0 && <VideoSection
        src={videos[0].video}
      /> }
      <RoadMapSection />
      <CollectionSection />
      <GalyPointsSection />
      <MarketPlaceSection />
      <BannerSection />
      <GallerySection />
      {videos.length > 0 && <VideoSection

          src={videos[1].video}
      /> }
      {/*<FaqSection />*/}
      <TeamSection />
    </div>
  );
};

export default HomePage;
