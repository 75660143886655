import React from 'react';
import {Link, NavLink} from 'react-router-dom';

const GalyPointsSection = () => {
  return (
    <section id='Galypoints' className='container GalyPoints-section mb-5'>
      <div className='row gy-4'>
        <div className='col-12 col-lg-10 col-xl-8'>
          <div className='py-5'>
            <ul className='nav flex-column gap-4'>
              <li>
                <div className='big-title font-migra text-uppercase mb-2'>
                  GALYPOINTS
                </div>
              </li>
              <li>
                <p className='mb-0 fw-normal lh-lg text-white text-opacity-75'>
                  The GALYPOINTS system was created for the community, to offer
                  real value to the NFT’s while maintaining fairness and a
                  healthy competitive spirit.
                </p>
              </li>
              <li>
                <p className='mb-0 fw-normal lh-lg text-white text-opacity-75'>
                  For every eagle you hold, you will gain a daily amount of
                  points. The number of daily points are dependent on your eagle
                  rank. With these points you will be able to purchase different
                  surprises on our website such as gifts, exclusive events,
                  NFT’S equivalent, whitelists, and more!
                </p>
              </li>
              <li>
                <p className='mb-0 fw-normal lh-lg text-white text-opacity-75'>
                  We offer every holder to earn a large number of points, even
                  if you don’t have a LEGENDARY EAGLE. You can see which trait
                  combinations will buy you a bonus on the number of daily
                  points you earn.
                </p>
              </li>
              <li className='mt-4'>
                <ul className='nav gap-2'>
                  <li>
                    <NavLink to={'/galypoints'}
                      className='btn btn-g-primary rounded-pill px-4 py-2 text-capitalize'
                    >
                      How It Works
                    </NavLink>
                  </li>
                  <li>
                    <span style={{display: "flex", justifyContent: "center"}}>
            <a target='_blank' style={{textAlign: 'center', textDecoration: 'underline', marginBottom: '24px'}} href={`${process.env.PUBLIC_URL}/terms.pdf`}>T&C</a>
            <a target='_blank' style={{textAlign: 'center', textDecoration: 'underline', marginBottom: '24px'}} href={`${process.env.PUBLIC_URL}/store-rules.pdf`}>MEC Store rules</a>
          </span>
                  </li>
                  <li>
                    {/*<Link target={'_blank'} to={`${process.env.PUBLIC_URL}/terms.pdf`}*/}
                    {/*         className='btn btn-g-primary rounded-pill px-4 py-2 text-capitalize'*/}
                    {/*>*/}
                    {/*  Terms & Conditions*/}
                    {/*</Link>*/}
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
};

export default GalyPointsSection;
