import FooterPrimary from "./components/footers/FooterPrimary";
import NavbarPrimary from "./components/navbars/NavbarPrimary";
import Paths from "./Paths";
import { useConnected } from "./shared/hooks/useConnected";
import { useDispatch } from "react-redux";
import { usePoints } from "./shared/hooks/usePoints";
import { useCallback, useEffect } from "react";
import { setUserWallet } from "./store/actions/galypoints-actions";
import { getMarketplaceItems } from "./store/actions/marketplace-actions";
import { galypointsActions } from "./store/slices/galypoints-slice";
import { marketplaceActions } from "./store/slices/marketplace-slice";
import axios from "axios";
import { useLocation } from "react-router";

function App() {
  const [isConnected] = useConnected();
  const dispatch = useDispatch();
  const [getPoints] = usePoints();



  const setUserWallet = useCallback(() => {
    const wallet = localStorage.getItem("wallet");
    dispatch(galypointsActions.setWallet(wallet));
  }, []);

  const getMarketplaceItems = useCallback(async () => {
    dispatch(marketplaceActions.setIsLoading(true));

    try {
      const response = await axios.get(
        `${process.env.REACT_APP_GALYPOINTS_API}/product`
      );
      dispatch(marketplaceActions.setItems(response.data));
    } catch (e) {
    } finally {
      dispatch(marketplaceActions.setIsLoading(false));
    }
  }, []);

  useEffect(() => {
    if (isConnected) {
      setUserWallet();
      getPoints();
    }

    getMarketplaceItems();
  }, [dispatch, isConnected, setUserWallet, getMarketplaceItems]);
  return (
    <div>
      <div className="d-flex flex-column flex-fill">
        <NavbarPrimary />
        <Paths />
        <FooterPrimary />
      </div>
    </div>
  );
}

export default App;
