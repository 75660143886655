import React from "react";

type Props = {
  price: number;
  isMarketplace: boolean;
};

const RegularBadge = (props: Props) => {
  const { price, isMarketplace } = props;
  return (
    <span className="position-absolute bottom-0 end-0">
      <span className={`badge-1 ${isMarketplace && 'market-tag' }`}>
        <img
          className="d-block h-100 w-100"
          src="/assets/images/Coming-soon.svg"
          alt="..."
        />
        <span className="badge-1-name text-white fw-bold">{price} Points</span>
      </span>
    </span>
  );
};

export default RegularBadge;
