import React, {useState} from "react";
import {Accordion, Card, useAccordionButton} from "react-bootstrap";
import {Link} from "react-router-dom";

function CustomToggle({
                          children,
                          eventKey,
                          onShow,
                      }: {
    children: React.ReactNode;
    eventKey: string;
    onShow: () => void;
}) {
    const decoratedOnClick = useAccordionButton(eventKey, () =>
        console.log("totally custom!")
    );

    return (
        <Link
            to="/"
            className="text-decoration-none w-100 d-block border-bottom border-secondary"
            onClick={(e) => {
                decoratedOnClick(e);
                onShow();
            }}
        >
            {children}
        </Link>
    );
}

const RoadMapSection = () => {
    const [show, setShow] = useState("AIRDROPS");

    const roadMapList = [

        {
            title: "Eden Gallery IRL events",
            text: `Exclusive IRL and Metaverse events in Eden gallery locations , working with an event planning team to bring you amazing experiences`,
        },
        {
            title: "Hot air balloon flights",
            text: `Raffles on hot air balloon flight raffles for holders. Soar to the skies like an eagle and enjoy a breathtaking view from above.`,
        },
        {
            title: "Signed artworks by Gal Yosef",
            text: `Raffles on signed artworks of the highest quality created by Gal Yosef & Eden gallery.`,
        },
        {
            title: 'Private jet flights',
            text: `Private jet flight raffles to the events for holders. A luxury ride to our events.`,
        },
        {
            title: 'Zero G Flights',
            text: `Zero G flight experience raffles for holders. Experience true weightlessness in a unique experience that simulates 0 gravity.`,
        },
        {
            title: 'Meta Eagle NFT airdrops',
            text: `Airdrops of high quality fine art collectibles, NFT’s, and other items to holders.`,
        },
        {
            title: 'Helicopter flights',
            text: `Helicopter ride experience raffles for holders.`,
        },
        {
            title: 'Business class flight tickets to events',
            text: `Business class flight raffles for holders to enjoy a more comfortable experience`,
        },
        {
            title: 'Skydiving jumps',
            text: `Tandem skydiving experience raffles for holders.`,
        },
        {
            title: 'Artist meetup in the metaverse',
            text: `Get together with our community and with our artist in the metaverse for various events.`,
        },
        {
            title: 'Exclusive physical artworks and collectibles',
            text: `Physical artwork raffles for holders on fine art collectibles with the highest quality.`,
        },
        {
            title: 'Donating 100,000 USD to eagle protection',
            text: `A donation of 100,000 USD to be spread across multiple organizations that will be used to help and preserve eagles around the world.`,
        },

    ];

    return (
        <section id="RoadMap" style={{marginTop: '60px'}} className="container roadMap-section mb-5 pb-5">
            <div>
                <div className="big-title font-migra text-uppercase mb-4">RoadMap</div>
                <Accordion
                    as={"ul"}
                    className="nav flex-column gap-4"
                    defaultActiveKey="AIRDROPS"
                >
                    {roadMapList.map((item, index) => (
                        <Card key={index} className="bg-dark rounded-lg p-3">
                            <Card.Header>
                                <CustomToggle
                                    eventKey={item.title}
                                    onShow={() => setShow(item.title)}
                                >
                                    <div className="d-flex align-items-center">
                                        <h1 className="mb-0 text-gray text-uppercase fw-bolder me-auto">
                                            {item.title}
                                        </h1>
                                        <button type="button" className="btn p-0 icon-size-2">
                                            {show === item.title ? (
                                                <img
                                                    className="d-block h-100 w-100"
                                                    src="/assets/images/MInus-close.svg"
                                                    alt="..."
                                                />
                                            ) : (
                                                <img
                                                    className="d-block h-100 w-100"
                                                    src="/assets/images/plus-open.svg"
                                                    alt="..."
                                                />
                                            )}
                                        </button>
                                    </div>
                                </CustomToggle>
                            </Card.Header>
                            <Accordion.Collapse eventKey={item.title}>
                                <Card.Body>
                                    <div className="px-0 col-12 col-md-10 col-lg-9 col-xl-8">
                                        <div className="lead text-white text-opacity-75">
                                            {item.text}
                                        </div>
                                    </div>
                                </Card.Body>
                            </Accordion.Collapse>
                        </Card>
                    ))}
                    <div className="position-relative">
                        <a
                            href={"https://opensea.io/collection/metaeagleclub-mec"}
                            target="_blank"
                            className="btn btn-g-primary rounded-lg p-4 text-capitalize d-flex align-items-center justify-content-center w-100"
                        >
              <span className="icon-size-1 me-2">
                <img
                    className="d-block h-100 w-100"
                    src="/assets/images/opensea label Desktop button.svg"
                    alt="..."
                />
              </span>
                            <h1 className="mb-0 text-white fw-bolder">Buy an Eagle</h1>
                        </a>
                        <span className="position-absolute top-0" style={{zIndex: "-1"}}>
              <img
                  className="d-block h-100 w-100"
                  src="/assets/images/scutch arrow second.svg"
                  alt="..."
              />
            </span>
                    </div>
                </Accordion>
            </div>
        </section>
    );
};

export default RoadMapSection;
