import React from "react";

export const GalyPoints = () => {

    return (
        <>
        <img
            src="/gp-rules.jpeg"
            alt="Galypoints System"
        />
            <img
                src="/gp-combo-1.jpeg"
                alt="Galypoints System"
            />
            <img
                src="/gp-combo-2.jpeg"
                alt="Galypoints System"
            />
            <div style={{textAlign: 'center', fontSize: '32px'}}>
                <a target='_blank' style={{textAlign: 'center', marginBottom: '24px'}} href={`${process.env.PUBLIC_URL}/store-rules.pdf`}>MEC Store rules</a><br/>
                <a target='_blank' href={`${process.env.PUBLIC_URL}/terms.pdf`}>  <span data-hover={'Terms and Conditions'}>Terms and Conditions</span> </a>

            </div>
            </>

    )
}
