import {useDispatch} from "react-redux";
import {useEffect} from "react";
import {useApi} from "./useApi";
import {galypointsActions} from "../../store/slices/galypoints-slice";


export const usePoints = () => {

    const dispatch = useDispatch();

    const [request, data] = useApi<any>('galypoints');


    useEffect(() => {
      if(data) {
          dispatch(galypointsActions.setTotalPoints(data.points));
      }
    },[data])

    return [request] as const;

}
