
import classes from './trait-item.module.scss';

type ComponentProps = {
    type: string;
    name: string;
    percentage: number;
}

export const TraitItem = (props: ComponentProps) => {

    const {type, name, percentage} = props;


    return (
        <div className={classes.wrapper}>
            <div className={classes.title}>
                {type}
            </div>
            <div className={classes.trait}>
                {name}
            </div>
            <div className={classes.percentage}>
                {Math.round(percentage)}% have this trait
            </div>
        </div>
    )
}
