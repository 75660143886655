import classes from "./eagle-card.module.scss";
import { styled, Typography } from "@mui/material";
import MuiAccordion, { AccordionProps } from "@mui/material/Accordion";
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from "@mui/material/AccordionSummary";

import MuiAccordionDetails from "@mui/material/AccordionDetails";
import React, { SyntheticEvent, useState } from "react";
import { TraitItem } from "./trait-item/trait-item";
import { format } from "date-fns";
import useResponsiveQuery from "../../../utils/isMobile";
//@ts-ignore
import { CopyToClipboard } from "react-copy-to-clipboard";
import copy from "../../../assets/svgs/copy.svg";

type ComponentProps = {
  eagle: any;
  isEagle: boolean;
};

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    // expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark"
      ? "rgba(255, 255, 255, .05)"
      : "rgba(0, 0, 0, .03)",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));

export const EagleCard = (props: ComponentProps) => {
  const isMobile = useResponsiveQuery();
  const { eagle, isEagle } = props;
  const [expanded, setExpanded] = useState<string | false>(false);
  const [copied, setCopied] = useState<boolean>(false);
  const handleChange =
    (panel: string) => (event: SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false);
    };

  const handleDownload = () => {
    window.open(
      "https://mec-4k.s3.eu-central-1.amazonaws.com/Eagles+-+4k/Eagles+-+4k/7898.png",
      "_blank"
    );
  };
  return (
    <Accordion
      expanded={expanded === "expanded"}
      onChange={handleChange("expanded")}
      className="d-flex flex-column card-height mt-0 pt-4"
    >
      <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
        <div className={classes.wrapper}>
          <div
            className={`${classes.image} ${isMobile && classes.mobileImage}`}
          >
            {isEagle && (
              <div
                className={classes.image}
                style={{ backgroundImage: `url(${eagle.imageUrl})` }}
              />
            )}
            {!isEagle && (
              <div
                className={classes.image}
                style={{ backgroundImage: `url(${eagle.product.image})` }}
              />
            )}
            {/*<img alt={eagle.id} className={classes.eagleImage} src={eagle.product.image}/>*/}
          </div>
          {isEagle && (
            <div className={classes.details}>
              <div className={classes.rankAndLinks}>
                <div className={classes.rankAndId}>
                  <span className={classes.id}>Eagle {eagle.id}</span>
                  <span className={classes.rank}> Rank {eagle.rank}</span>
                </div>
                <div className={classes.actionButtons}>
                  <div>
                    <a
                      className={classes.downloadButton}
                      href={`https://mec-4k.s3.eu-central-1.amazonaws.com/Eagles+-+4k/Eagles+-+4k/${eagle.id}.png`}
                      target="_blank"
                    >
                      Download 4K
                    </a>
                  </div>
                  <a
                    className={classes.openSeaButton}
                    href={`https://opensea.io/assets/ethereum/0xeb6dffb87315a2bdf4dedf72b993adc960773a0d/${eagle.id}`}
                    target="_blank"
                  >
                    <img
                      className={classes.openSea}
                      src="https://seeklogo.com/images/O/opensea-logo-7DE9D85D62-seeklogo.com.png"
                    />
                  </a>
                </div>
              </div>
            </div>
          )}

          <div className={classes.separator} />
          <div className={classes.details}>
            <div className={classes.rankAndLinks}>
              <div className={classes.rankAndId}>
                {!isEagle && (
                  <span className={classes.id}>{eagle.product.name}</span>
                )}
              </div>
              <div className={classes.date}>
                {!isEagle && format(eagle.date, "L/d/yyyy")}
              </div>
            </div>
            {!isEagle && <div className={`${classes.separator} my-3`} />}

            <div className={classes.properties}>
              <span className={classes.title}>
                {" "}
                {expanded ? "Less" : "More"}{" "}
              </span>
              <img
                className={expanded ? classes.rotate : ""}
                src="/chevron.svg"
                alt="chevron"
              />
            </div>
          </div>
        </div>
      </AccordionSummary>
      <AccordionDetails>
        {!isEagle && (
          <div className={`${classes.eagleDetails}`}>
            {eagle.status === "Pending" && (
              <div className={classes.status}>
                <span>
                  Purchase was not confirmed yet. Please check within 48 hours
                  to get your coupon
                </span>
              </div>
            )}
            {eagle.status === "Confirmed" && (
              <div className={classes.status}>
                <span>
                  Coupon code:
                  <div className={classes.copy}>
                    {eagle.coupon}
                    <CopyToClipboard
                      onCopy={() => setCopied(true)}
                      text={eagle.coupon}
                    >
                      <span>
                        <img className={classes.copyButton} src={copy} />
                      </span>
                    </CopyToClipboard>
                  </div>
                  {copied && <span style={{ color: "red" }}>Copied.</span>}
                </span>
              </div>
            )}

            {eagle.status === "Declined" && (
              <div className={classes.status}>
                <span>Sorry, your purchase was not successful.</span>
              </div>
            )}
          </div>
        )}
        {isEagle && (
          <>
            <div className={classes.eagleDetailsAndTraits}>
              <div className={classes.traits}>
                {eagle.traits.map((trait: any) => (
                  <TraitItem
                    type={trait.type}
                    name={trait.name}
                    percentage={trait.percentage}
                    key={`${trait.type}-${trait.name}-${trait.percentage}`}
                  />
                ))}
              </div>
              <div className={classes.dailyPoints}>
                Daily points: {eagle.points / 10}
              </div>
            </div>
          </>
        )}
      </AccordionDetails>
    </Accordion>
  );
};
