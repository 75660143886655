import React, {useCallback, useEffect, useState} from "react";
import { Nav, Tab } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import AllTab from "./tabsSections/AllTab";
import axios from "axios";
import BannerSection from "../home/Sections/BannerSection";

type Props = {};

const GalleryPage = (props: Props) => {
  const location = useLocation;
  const tabsList = [
    { name: "All" },
    { name: "Events" },
    { name: "Art" },
    { name: "Holders" },
    { name: "Media" },
  ];

  const [images, setImages] = useState([]);


  const getImages = useCallback(async () => {
    try {
      const res = await axios(`${process.env.REACT_APP_GALYPOINTS_API}/gallery`);
      setImages(res.data);

    } catch (e) {

    }


  }, [process.env.REACT_APP_GALYPOINTS_API])
  useEffect(() => {
    getImages()
  }, [getImages])

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return (
    <div
      className="bg-fixed"
      style={{ backgroundImage: "url(/assets/bg-star.gif)" }}
    >
      <Tab.Container defaultActiveKey="All">
        <section className="container my-5">
          <div className="nav border-bottom">
            <div className="big-title font-migra text-uppercase me-auto">
              GALLERY
            </div>
            <Nav className="cl-effect-5 gap-2 align-self-end mb-2">
              {tabsList.map((item, index) => (
                <Nav.Item key={index}>
                  <Nav.Link eventKey={item.name}>
                    <span data-hover={item.name}>{item.name}</span>
                  </Nav.Link>
                </Nav.Item>
              ))}
            </Nav>
          </div>
        </section>
        <section className="banner-swiper-section mb-5">

          <BannerSection />
        </section>
        <section className="container mb-5 pb-5">
          <Tab.Content>
            <Tab.Pane eventKey="All">
              <AllTab  images={images} />
            </Tab.Pane>
            <Tab.Pane eventKey="Events">
              <AllTab images={images.filter((item) => item.category.includes('Events'))} />
            </Tab.Pane>
            <Tab.Pane eventKey="Art">
              <AllTab images={images.filter((item) => item.category.includes('Art'))} />
            </Tab.Pane>
            <Tab.Pane eventKey="Holders">
              <AllTab images={images.filter((item) => item.category.includes('Holders'))} />
            </Tab.Pane>
            <Tab.Pane eventKey="Media">
              <AllTab images={images.filter((item) => item.category.includes('Media'))} />
            </Tab.Pane>
          </Tab.Content>
        </section>
      </Tab.Container>
    </div>
  );
};

export default GalleryPage;
