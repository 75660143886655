import React from 'react';

function ArrowRightIcon() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='100%'
      height='100%'
      viewBox='0 0 47.758 40.23'
    >
      <g
        fill='none'
        stroke='currentColor'
        strokeWidth='1.5'
        data-name='Group 95470'
        transform='translate(0 -.184)'
      >
        <path
          d='M30.509 40.303s-3.013-20 17.249-20'
          data-name='Path 75074'
        ></path>
        <path
          d='M47.758 0L0 0'
          data-name='Line 1005'
          transform='translate(0 20.299)'
        ></path>
        <path d='M30.509.295s-3.013 20 17.249 20' data-name='Path 75084'></path>
      </g>
    </svg>
  );
}

export default ArrowRightIcon;
