import React, { useState } from 'react';
import { Link } from 'react-router-dom';

const HeaderBanner = () => {
  const [banner, setBanner] = useState(true);
  return (
    <div
      className={`d-xl-none bg-g-primary p-2 ${banner ? 'd-block' : 'd-none'}`}
    >
      <div className='container'>
        <ul className='nav justify-content-center align-items-center gap-2'>
          <li className='small'>
            <span className='small'>Join us, be part of the community</span>
          </li>
          <li>
            <a href='https://opensea.io/collection/metaeagleclub-mec' target='_blank'
              className='btn btn-sm btn-dark rounded-pill px-3 py-2 text-capitalize d-flex align-items-center small'
            >
              <span className='icon-size-small-2 me-2'>
                <img
                  className='d-block h-100 w-100'
                  src='/assets/images/opensea label Desktop button.svg'
                  alt='...'
                />
              </span>
              <span className='small'>Buy an Eagle</span>
            </a>
          </li>
          <li>
            <button
              type='button'
              className='btn p-0 d-flex'
              onClick={() => setBanner(!banner)}
            >
              <img src='/assets/images/close.svg' alt='...' />
            </button>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default HeaderBanner;
