import React, { useState } from "react";
import { Link, NavLink } from "react-router-dom";
import HeaderBanner from "../banners/HeaderBanner";
import "./navbar-module.scss";
import { HashLink } from "react-router-hash-link";

const NavbarPrimary = () => {
  const [show, setShow] = useState(false);
  const [navbar, setNavbar] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const changeBackground = () => {
    if (window.scrollY >= 180) {
      setNavbar(true);
    } else {
      setNavbar(false);
    }
  };

  window.addEventListener("scroll", changeBackground);

  const links = [
    { name: "Home", link: "/#Home" },
    { name: "About The Project", link: "/about-project", isPage: true },
    { name: "RoadMap", link: "/#RoadMap" },
    { name: "Galypoints", link: "/#Galypoints" },
    { name: "Marketplace", link: "/marketplace", isPage: true },
    { name: "Gallery", link: "/#Gallery" },
    { name: "About The Artist", link: "/about-artist", isPage: true },
    { name: "Team", link: "/#Team" },
    // { name: "F&Q", link: "/#Faq" },
  ];

  return (
    <>
      {/* Desktop */}
      <header className={`sticky-top ${navbar && ""}`}>
        <nav className="py-3 py-md-2 bg-black">
          <div className="container">
            <div className="d-flex align-items-center">
              <div className="me-auto">
                <a href="/#home" className="d-block logo">
                  <img
                    className="w-100 d-block"
                    src="/assets/images/galylogo.svg"
                    alt="logo"
                  />
                </a>
              </div>
              <div className="nav-links">
                <ul className="nav align-items-center gap-2">
                  <li className="d-none d-sm-block">
                    <a
                      href="https://opensea.io/collection/metaeagleclub-mec"
                      target="_blank"
                      className="btn btn-sm btn-g-primary rounded-pill px-3 py-2 icon-size-1"
                    >
                      <img
                        className="d-block h-100 w-100"
                        src="/assets/images/Big opensea icon.svg"
                        alt="..."
                      />
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://discord.com/invite/galyverse"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="btn p-0 icon-size-2"
                    >
                      <img
                        className="d-block h-100 w-100"
                        src="/assets/images/discord footer.svg"
                        alt="..."
                      />
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.instagram.com/galyversenft"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="btn p-0 icon-size-2"
                    >
                      <img
                        className="d-block h-100 w-100"
                        src="/assets/images/insta footer.svg"
                        alt="..."
                      />
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://twitter.com/galyverse"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="btn p-0 icon-size-2"
                    >
                      <img
                        className="d-block h-100 w-100"
                        src="/assets/images/twitter footer.svg"
                        alt="..."
                      />
                    </a>
                  </li>
                  <li className="d-xl-none">
                    <NavLink
                      to={"/eyrie"}
                      className="btn btn-sm btn-outline-gray rounded-pill text-capitalize"
                    >
                      <small>My eyrie</small>
                    </NavLink>
                  </li>
                  <li>
                    <button
                      type="button"
                      onClick={handleShow}
                      className="btn p-0 icon-size-small d-xl-none"
                    >
                      <img
                        className="d-block h-100 w-100"
                        src="/assets/images/menu.svg"
                        alt="..."
                      />
                    </button>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </nav>
        {/* === */}
        <nav className="py-2 bg-dark d-none d-xl-block">
          <div className="container">
            <div className="d-flex align-items-center">
              <ul className="nav align-items-center gap-4 cl-effect-5 text-capitalize">
                {links.map((item, index) => (
                  <li key={index} className="d-none d-xl-block">
                    {item.isPage ? (
                      <NavLink to={item.link} className="text-capitalize">
                        <span data-hover={item.name}>{item.name}</span>
                      </NavLink>
                    ) : (
                      <HashLink smooth to={item.link}>
                        <span data-hover={item.name}>{item.name}</span>
                      </HashLink>
                    )}
                  </li>
                ))}
              </ul>
              <ul className="nav gap-4 ms-auto">
                <li>
                  <NavLink
                    to={"/eyrie"}
                    className="btn btn-sm btn-secondary rounded-pill px-3 py-1 text-capitalize"
                  >
                    My Eyrie
                  </NavLink>
                </li>
              </ul>
            </div>
          </div>
        </nav>
        {/* === */}
        <HeaderBanner />
      </header>

      {/* Mobile */}

      <aside className={`sidebar ${show ? "show" : "hide"}`}>
        <div className="d-flex h-100">
          <div className="px-0 col-9 col-md-5 col-lg-4">
            <div className="offcanvas-header bg-black w-100">
              <div className="d-flex align-items-center w-100 justify-content-between">
                <Link to="/" className="logo">
                  <img
                    className="h-100 w-100 d-block"
                    src="/assets/images/galylogo.svg"
                    alt="logo"
                  />
                </Link>
                <button
                  type="button"
                  onClick={handleClose}
                  className="btn-close btn-close-white"
                />
              </div>
            </div>
            <div className="offcanvas-body h-100 bg-dark">
              <div className="nav-links">
                <ul className="nav flex-column gap-3 ps-2 cl-effect-5">
                  {links.map((item, index) => (
                    <li key={index}>
                      <HashLink smooth to={item.link}>
                        <span data-hover={item.name}>{item.name}</span>
                      </HashLink>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
          <div
            className="flex-fill bg-white bg-opacity-50"
            onClick={handleClose}
          />
        </div>
      </aside>
    </>
  );
};

export default NavbarPrimary;
