import React, { useCallback, useRef } from "react";
import { Link } from "react-router-dom";
// Import Swiper React components
import { Swiper, SwiperSlide, SwiperProps } from "swiper/react";
// import required modules
import { Keyboard, Scrollbar } from "swiper";

// icons
import ArrowRightIcon from "../../../assets/icons/ArrowRightIcon";
import ArrowLeftIcon from "../../../assets/icons/ArrowLeftIcon";

const CollectionSection = () => {
  const sliderRef = useRef(null);

  const handlePrev = useCallback(() => {
    if (!sliderRef.current) return;
    sliderRef.current.swiper.slidePrev();
  }, []);

  const handleNext = useCallback(() => {
    if (!sliderRef.current) return;
    sliderRef.current.swiper.slideNext();
  }, []);

  const CollectionList = [
    {},
    {},
    {},
    {},
    {},
    {},
    {},
    {},
    {},
    {},
    {},
    {},
    {},
    {},
    {},
    {},
    {},
  ];

  return (
    <section className="container collection-section pt-5 mb-5 pb-3">
      <div className="position-relative pt-lg-5 mt-lg-5">
        <Swiper
          modules={[Keyboard, Scrollbar]}
          scrollbar={true}
          keyboard={{
            enabled: true,
          }}
          ref={sliderRef}
          slidesPerView={2}
          spaceBetween={10}
          breakpoints={{
            640: {
              slidesPerView: 2,
              spaceBetween: 10,
            },
            768: {
              slidesPerView: 2,
              spaceBetween: 20,
            },
            1024: {
              slidesPerView: 3,
              spaceBetween: 30,
            },
          }}
        >
          {CollectionList.map((item, i) => (
            <SwiperSlide key={i}>
              <Link to="" className="text-decoration-none text-white">
                <img src={`/assets/images/Egaels/asset-${i}.jpg`} alt="..." />
              </Link>
            </SwiperSlide>
          ))}
        </Swiper>
        {/* buttons */}
        <div
          className="mt-4 d-none d-md-flex justify-content-end position-absolute end-0 bg-black rounded-pill"
          style={{ bottom: "-0.9375rem", zIndex: "40" }}
        >
          <button
            type="button"
            className="btn btn-arrow prev-arrow"
            onClick={handlePrev}
          >
            <ArrowLeftIcon />
          </button>
          <button
            type="button"
            className="btn btn-arrow next-arrow"
            onClick={handleNext}
          >
            <ArrowRightIcon />
          </button>
          <a  href={'https://opensea.io/collection/metaeagleclub-mec'} target='_blank' className="btn btn-dark rounded-pill py-2 px-4">
            See Listed Collection
          </a>
        </div>
        {/* === */}
        <div className="d-md-none mt-4 text-end">
          <a href={'https://opensea.io/collection/metaeagleclub-mec'} target='_blank' className="btn btn-dark rounded-pill py-2 px-4">
            See Listed Collection
          </a>
        </div>
        {/* /buttons */}
      </div>
    </section>
  );
};

export default CollectionSection;
