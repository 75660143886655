import React from 'react';

interface Props { 
  src: string;
}

const VideoSection = ({ src }: Props) => {
  return (
    <section className='container mt-5 pt-5'>
      <div className='d-flex flex-column h-100 w-100'>
          <video
              src={src}
              playsInline
              muted={true}
              autoPlay
              loop
              className='h-100 w-100'
           />
      </div>
    </section>
  );
};

export default VideoSection;
