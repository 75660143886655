import React, {useCallback, useEffect, useState} from "react";
import {useWeb3React} from "@web3-react/core";
import axios from "axios";
import {connectors} from "../../../../utils/connectors";
import Web3 from 'web3';
import {Box, Modal, Typography} from "@mui/material";
import classes from './galy-modal.module.scss';
// import useResponsiveQuery from "../../../../utils/isMobile";
import {useConnected} from "../../../../shared/hooks/useConnected";
import {Bars} from "react-loader-spinner";
import {galypointsActions} from "../../../../store/slices/galypoints-slice";
import {DefaultRootState, useDispatch, useSelector} from "react-redux";
import useResponsiveQuery from "../../../../utils/isMobile";
// "react-card-flip": "^1.1.5",
// "devDependencies": {
//     "@types/lodash": "^4.14.178",
//         "@types/react-helmet": "^6.1.5",
//         "@types/react-modal": "^3.13.1",
//         "assert": "^2.0.0",
//         "buffer": "^6.0.3",
//         "crypto-browserify": "^3.12.0",
//         "https-browserify": "^1.0.0",
//         "os-browserify": "^0.3.0",
//         "process": "^0.11.10",
//         "react-app-rewired": "^2.2.1",
//         "sass": "^1.49.0",
//         "stream-browserify": "^3.0.0",
//         "stream-http": "^3.2.0",
//         "url": "^0.11.0"
// }

//     "@emotion/styled": "^11.8.1",
//     "@mui/material": "^5.6.4",
//     "@web3-react/core": "^6.1.9",
//     "@web3-react/injected-connector": "^6.0.7",
//     "@web3-react/walletconnect-connector": "^6.2.13",
//     "@web3-react/walletlink-connector": "^6.2.14",


// "react-helmet": "^6.1.0",
type ComponentProps = {
    isOpen: boolean;
    onClose: () => void;
}

const web3 = new Web3(Web3.givenProvider);
export const GalyModal = (props: ComponentProps) => {
    const isMobile = useResponsiveQuery();
    const {isOpen, onClose} = props;
    const {account, activate, connector} = useWeb3React();
    const {wallet} = useSelector((state: DefaultRootState) => state.galypoints);

    const [loading, setLoading] = useState(false);
    const [isConnected] = useConnected();
    const dispatch = useDispatch();

    const connect = async (activeConnector: any) => {
        setLoading(true);
        await activate(activeConnector);
    }

    const login = useCallback(async () => {
        try {
            const address = account!.toLowerCase()
            // @ts-ignore
            const nonce = await getNonce(address);
            // @ts-ignore
            const signature = await web3.eth.personal.sign(
                web3.utils.sha3(`Welcome message, nonce: ${nonce}`)!,
                address
            );

            const {data} = await axios.post(
                `${process.env.REACT_APP_GALYPOINTS_API}/login`,
                {
                    address,
                    signature,
                },
                {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                }
            );
            if (data && data.AccessKeyId) {
                localStorage.setItem('auth', JSON.stringify({
                    AccessKeyId: data.AccessKeyId,
                    SecretKey: data.SecretKey,
                    SessionToken: data.SessionToken
                }));
                localStorage.setItem('wallet', address);
                dispatch(galypointsActions.setWallet(address));

                window.dispatchEvent(new Event("storage"));
            }
        } catch (e) {
        } finally {
            setLoading(false);
            onClose();
        }

    }, [account]);

    useEffect(() => {

        if ((!!account && !isConnected && !wallet)) {

            login()

        }
    }, [account, login, isConnected])

    const getNonce = async (address: string) => {
        try {
            const {
                data: {nonce},
            } = await axios(
                `${process.env.REACT_APP_GALYPOINTS_API}/nonce?address=${address.toLowerCase()}`,
                {
                    method: 'GET',
                }
            );

            return nonce;
        } catch (e) {
            const {data} = await axios.post(
                `${process.env.REACT_APP_GALYPOINTS_API}/signup`,
                {address: address},
                {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                }
            );

            return data.Attributes.nonce;
        }
    }


    const style = {
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: isMobile ? '80%' : 400,
        bgcolor: '#1E242C',
        color: 'white',
        // border: '2px solid #000',
        borderRadius: '8px',
        boxShadow: 24,
        p: 4,
        height: '300px',
        padding: isMobile ? '20px' : '32px',
        outline: 'transparent'
    };

    return (
        <Modal
            open={isOpen}
            onClose={onClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"

        >
            <Box sx={style}>
                <Typography id="modal-modal-title" variant="h5" className={classes.header} component="h5">
                    {!loading ? 'Select Wallet Provider' : 'Connecting'}
                </Typography>
                {loading ?
                    <div className={classes.loading}>
                        <Bars color="#00BFFF" height={100} width={100}/>
                    </div>
                    :
                    <div id="modal-modal-description">
                        <div className={classes.buttons}>
                            <div
                                className={classes.button}
                                onClick={() => {
                                    connect(connectors.coinbaseWallet);
                                }}
                            >
                                <div className={classes.buttonWrapper}>
                                    <img
                                        className={classes.image}
                                        src="/cbw.png"
                                        alt="Coinbase Wallet Logo"

                                    />
                                    <span className={classes.text}>Coinbase</span>
                                </div>
                            </div>
                            <div
                                className={classes.button}
                                onClick={() => {
                                    connect(connectors.injected);
                                }}
                            >
                                <div className={classes.buttonWrapper}>
                                    <img
                                        className={classes.image}
                                        src="/mm.png"
                                        alt="Metamask Logo"
                                    />
                                    <span className={classes.text}>Metamask</span>
                                </div>
                            </div>
                        </div>
                    </div>
                }

            </Box>
        </Modal>
    )
}
