import { useCallback, useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
// import required modules
import { Keyboard, Scrollbar } from "swiper";
import axios from "axios";

// icons
import ArrowRightIcon from "../../../assets/icons/ArrowRightIcon";
import ArrowLeftIcon from "../../../assets/icons/ArrowLeftIcon";
import ComingSoonBadge from "../../../components/badges/ComingSoonBadge";
import SoldOutBadge from "../../../components/badges/SoldOutBadge";
import { useSelector } from "react-redux";
import RegularBadge from "../../Marketplace/components/badges/RegularBadge";

const MarketPlaceSection = () => {
  const { items, isLoading } = useSelector((state) => state.marketplace);
  const sliderRef = useRef(null);

  const handlePrev = useCallback(() => {
    if (!sliderRef.current) return;
    sliderRef.current.swiper.slidePrev();
  }, []);

  const handleNext = useCallback(() => {
    if (!sliderRef.current) return;
    sliderRef.current.swiper.slideNext();
  }, []);

  useEffect(() => {
    // api call goes here
    axios
      .get("/")
      .then((res) => {
        //Everything we need to do with response goes here
        // setData(res.data)
      })
      .catch((err) => console.log(err));
  }, []);

  return (
    <section
      id="Marketplace"
      className="container collection-section mb-5 pb-5"
    >
      <div className="big-title font-migra text-uppercase mb-5 pb-3">
        MARKET PLACE
      </div>
      <div className="position-relative">
        <Swiper
          ref={sliderRef}
          modules={[Keyboard, Scrollbar]}
          scrollbar={true}
          keyboard={{
            enabled: true,
          }}
          slidesPerView={2}
          spaceBetween={10}
          breakpoints={{
            640: {
              slidesPerView: 2,
              spaceBetween: 10,
            },
            768: {
              slidesPerView: 2,
              spaceBetween: 20,
            },
            1024: {
              slidesPerView: 4,
              spaceBetween: 30,
            },
          }}
        >
          {items?.map((item, i) => (
            <SwiperSlide key={i}>
              <Link to="" className="d-block text-decoration-none">
                <div className="position-relative" style={{ height: "330px" }}>
                  <img
                    className="d-block h-100 w-100"
                    //src={`/assets/images/Market-images/market-${i}.jpg`}
                    src={item?.file}
                    alt="..."
                  />
                  {item.status === "Soon" && (
                    <ComingSoonBadge isMarketplace={true} />
                  )}
                  {item.status === "Running" && item.totalLeft > 0 && (
                    <RegularBadge isMarketplace={true} price={item.price} />
                  )}
                  {item.totalLeft === 0 && (
                    <SoldOutBadge isMarketplace={true} />
                  )}
                  {/* {item.status === "Soon" && <ComingSoonBadge />}
                  {item.status === "Hold" && <SoldOutBadge />}
                  {item.status === "Running" && (
                    <span className="bg-primary text-white px-3 py-2 small text-capitalize rounded-lg-right-top position-absolute bottom-0 end-0">
                      <div className="text-uppercase">{item?.type}</div>
                      <div>{item?.price} Points</div>
                    </span>
                  )} */}
                </div>
                {item.status === "Running" && (
                  <div className="mt-3 small">
                    <p className="mb-1 text-uppercase text-white">
                      {item?.name}
                    </p>
                    <p className="mb-0 text-white-50">
                      {item?.totalLeft}/{item?.stock} Remaining
                    </p>
                  </div>
                )}
              </Link>
            </SwiperSlide>
          ))}
        </Swiper>

        {/* buttons */}
        <div
          className="mt-4 d-none d-md-flex justify-content-end position-absolute end-0 bg-black rounded-pill"
          style={{ bottom: "-0.9375rem", zIndex: "40" }}
        >
          <button
            type="button"
            className="btn btn-arrow prev-arrow"
            onClick={handlePrev}
          >
            <ArrowLeftIcon />
          </button>
          <button
            type="button"
            className="btn btn-arrow next-arrow"
            onClick={handleNext}
          >
            <ArrowRightIcon />
          </button>
          <Link to="" className="btn btn-dark rounded-pill py-2 px-4">
            View All
          </Link>
        </div>
        {/* === */}
        <div className="d-md-none mt-4 text-end">
          <Link to="" className="btn btn-dark rounded-pill py-2 px-4">
            View All
          </Link>
        </div>
        {/* /buttons */}
      </div>
    </section>
  );
};

export default MarketPlaceSection;
