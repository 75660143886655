import React, { useEffect, useState } from "react";
import { Navigation, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import BannerCard from "../../../components/banners/BannerCard";
import GalleryCard from "../../../components/cards/GalleryCard";
import BannerSection from "../../home/Sections/BannerSection";

type Props = {};

export interface ISignUpData {
  image: string;
  type: string;
  category: string;
}

interface IMyProps {
  images?: any;
}

const AllTab: React.FC<IMyProps> = ({ images }: IMyProps) => {


  const bannerList = [{}, {}, {}];

  return (
    <div>

      <section>
        <div className="row row-cols-2 row-cols-lg-3">
          {images.map((item, index) => {
            return (
              <div key={index} className="p-0">
                <GalleryCard item={item} />
              </div>
            );
          })}
        </div>
      </section>
    </div>
  );
};

export default AllTab;
