import {useState} from "react";
import {AxiosError, AxiosRequestConfig} from "axios";
import {AwsClient} from "aws4fetch";

interface ApiOptions {
    baseUrl: string;
    authorizeRequest: boolean;
    defaultData: unknown;
}

interface AuthData {
    AccessKeyId: string;
    SecretKey: string;
    SessionToken: string;
}

export type UseApiParams = Partial<ApiOptions>
const defaultParams: UseApiParams = {
    baseUrl: process.env.REACT_APP_GALYPOINTS_API,
    defaultData: null
}
export const useApi = <T>(path: string, params?: UseApiParams) => {
    const {defaultData, baseUrl} = {...defaultParams, ...params};
    const [data, setData] = useState<T>(defaultData as T);
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);

    const request = async (options?: RequestInit): Promise<void> => {
        setLoading(true);
        setError('');
        try {
            const token = await getToken();
            const aws = new AwsClient({
                accessKeyId: token.AccessKeyId,
                secretAccessKey: token.SecretKey,
                sessionToken: token.SessionToken,
                region: 'us-east-1',
                service: 'execute-api',
                retries: 0
            });
            const res: Response = await aws.fetch(`${baseUrl}/${path}`, options)
            const rawBody = await res.text();
            let body = null;
            if (rawBody) {
                body = JSON.parse(rawBody);
            }

            if (!res.ok) {
                res.status === 403 && localStorage.removeItem('auth');
                setError(body?.message);
                return;
            }

            setData(body);
        } catch (error: any) {
            setError('Something went wrong');
        } finally {
            setLoading(false);
        }
    }

    return [request, data, loading, error] as const;
}

const getToken = (): AuthData => {
    let token = localStorage.getItem('auth');
    if (!token) {
        throw Error('Unauthenticated');
    }
    return JSON.parse(token);
}
